
import CreateTabs from "../Create/createTabs.vue";
import Overview from "../Create/overview.vue";
import Vue from "vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
import {
	AdvertiserFilters,
	AdvertiserOptions,
} from "../../../../interfaces/advertiser";
import { initCampaign } from "../../../../utils/initData";
import Alertize from "../../../../components/Alertize.vue";
import { isEmpty, isNull, isUndefined } from "lodash";
import { Campaign } from "../../../../interfaces/campaign";
// @ts-ignore
import NavModifiersModels from "@/views/Admin/Campaigns/Dependencies/TabNavigation.vue";
// @ts-ignore
import BidModelContent from "@/views/Admin/Campaigns/Dependencies/Contents/bidModel.vue";
// @ts-ignore
import BidModifierContent from "@/views/Admin/Campaigns/Dependencies/Contents/bidModifier.vue";
// @ts-ignore
import DeliveryModelContent from "@/views/Admin/Campaigns/Dependencies/Contents/deliveryModel.vue";
// @ts-ignore
import DeliveryModifierContent from "@/views/Admin/Campaigns/Dependencies/Contents/deliveryModifier.vue";
// @ts-ignore
import EventsContent from "@/views/Admin/Campaigns/AssociationsEvents/AssociatedEvents.vue";

import {
	Notification,
	MessageTypes,
	// @ts-ignore
} from "@/interfaces/proccess";
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { 
	getPermisionModifiers,
	getPermisionCampaign
} from "@/utils/permissionResolve";

export default Vue.extend({
	name: "Edit",
	props: {},
	components: {
		CreateTabs,
		Overview,
		Alertize,
		NavModifiersModels,
		BidModelContent,
		BidModifierContent,
		DeliveryModelContent,
		DeliveryModifierContent,
		EventsContent,
	},
	data: () => ({
		title: "Create",
		currentTab: 0,
		items: ["Overview", "Modifiers / Models", "Events"],
		account: {
			currency: {
				name: "USD",
			},
			timezone: {
				name: "UTC",
			},
		},
		campaign: initCampaign(),
		campaignTemp: {},
		allows_events: false,
		start_time: "00:00:00",
		end_time: "23:59:59",
		date_format: "YYYY-MM-DD",
		time_format: "HH:mm:ss",
		optimization: 0,
		updateCountBidModifier: true,

		// Tab Modifiers and Models
		currentTabModifiers: 0,

		success: {
			type: MessageTypes.SUCCESS,
			title: i18n.t("title-success"),
			message: i18n.t("success"),
			btn_text: i18n.t("continue"),
			show: true,
			to: undefined,
			btn_text_redirect: i18n.t("create_new_line"),
			to_redirect: "LineItemCreate",
			show_redirect: false,
			query: undefined,
			params: undefined,
		} as Notification,

		fieldLabel: {
			daily_budget: "Daily Budget",
		},

		resources: {
			continents: [],
			types_frecuency_caps: [],
		},
	}),
	async created() {
		await this.handleShow(this.getID);
		this.campaign = await this.getParsedData();
		this.optimization = this.campaign.optimization_strategy_id;
	},
	async mounted() {
		this.dispatchAccount();
		this.dispatchBudgetTypes();
		this.dispatchAdvertisers();
		this.dispatchKpiCampaigns();
		this.dispatchStrategies();
		this.dispatchTraffickers();
		this.dispatchCampaignPacing();
		this.dispatchOptimizationStrategies();
		this.dispatchTypesFrecuencyCaps();
		await this.dispatchContinents();
		if (Array(this.campaign.frequency_caps).length) {
			this.dispatchUnitTimes();
		}
		await this.setIndexTab();
	},
	computed: {
		...mapGetters("profile", ["getAbility", "isAccountRM"]),
		isRetailerMedia() {
			return this.isAccountRM;
		},
		getCampaign() {
			return this.$store.state.campaign.campaign;
		},
		getID(): Number {
			return Number(this.$route.params.id);
		},
		hasID() {
			return !!this.$route.params.id;
		},
		getAccount() {
			return this.$store.state.account.account;
		},
		getBudgetTypes() {
			return this.$store.state.custom_list.budget_types;
		},
		getAdvertisers() {
			return this.$store.state.advertiser.advertisers_list;
		},
		getCampaignsPacing() {
			return this.$store.state.custom_list.campaigns_pacing;
		},
		getOptimizationStrategies() {
			return this.$store.state.custom_list.optimization_strategies;
		},
		getKpiCampaigns() {
			return this.$store.state.custom_list.kpi_campaigns;
		},
		getStrategies() {
			return this.$store.state.custom_list.strategies;
		},
		getUnitTimes() {
			return this.$store.state.custom_list.unit_times;
		},
		getTraffickers() {
			return this.$store.state.user.traffickers;
		},
		getModifier() {
			return this.$store.state.modifier.modifier;
		},
		getContinens() {
			return this.resources.continents;
		},
		getFrecuencyTypes() {
			return this.resources.types_frecuency_caps;
		},
		getItems() {
			this.items = [
				{
					key: 0,
					label: this.$t('campaign.tabs.overview'),
					disabled: false,
				},
				{
					key: 1,
					label: this.$t('campaign.tabs.modifier'),
					disabled: !this.canAccessModifers
				},
			];
			
			if(!this.isRetailerMedia) {
				this.items = [...this.items, {
					key: 2,
					label: this.$t('campaign.tabs.event'),
					disabled: !this.canAccessEvent
				}];
			}

			return this.items;
		},
		isCreatedEntity() {
			return this.hasData(this.getCampaign.id);
		},

		canAccessModifers(){
			return this.canUpdateModifier && this.isCreatedEntity;
		},

		canAccessEvent() {
			if(this.isRetailerMedia) return false;
			if(!this.canEvent) return false;
			return this.isCreatedEntity && this.allows_events;
		},

		canEvent(){
			return this.getAbility.can(this.getPermisionCampaigns.actions.event_asso, 
				this.getPermisionCampaigns.subject);
		},

		canCreateModifier(){
			return this.getAbility.can(this.getPermissionModifier.subComponent.bid_modifier
				.create_bid, this.getPermissionModifier.subject) ||
				this.getAbility.can(this.getPermissionModifier.subComponent.delivery_modifier
				.create_delivery, this.getPermissionModifier.subject);
		},

		canUpdateModifier(){
			return this.getAbility.can(this.getPermissionModifier.subComponent.bid_modifier
				.update_bid, this.getPermissionModifier.subject) ||
				this.getAbility.can(this.getPermissionModifier.subComponent.delivery_modifier
				.update_delivery, this.getPermissionModifier.subject);
		},

		canActiveCampaign(){
			return this.getAbility.can(this.getPermisionCampaigns.actions.active, this.getPermisionCampaigns.subject);
		},

		getPermissionModifier(){
			return getPermisionModifiers();
		},

		getPermisionCampaigns(){
			return getPermisionCampaign();
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("campaign", ["continentsList"]),

		handleCancelModifier() {
			this.$router.push({ name: "CampaignsIndex" });
		},

		async getParsedData() {
			let campaign = this.getCampaign;
			//await this.cloneCampaign(campaign);
			this.setCampaign(campaign);
			//campaign.automatic_allocation = campaign.automatic_allocation ? 1 : 0;

			this.start_time = this.formatDate(
				campaign.start_date,
				this.time_format
			);
			this.end_time = this.formatDate(
				campaign.end_date,
				this.time_format
			);

			// campaign.start_date = this.formatDate(
			// 	campaign.start_date,
			// 	this.date_format
			// );
			// campaign.end_date = this.formatDate(
			// 	campaign.end_date,
			// 	this.date_format
			// );

			return campaign;
		},
		formatDate(date: String, format: String) {
			return this.moment(date).format(format);
		},
		async updateSelectedTabIndex(index: number = 0) {
			if(this.getItems.find((t) => t.key == index).disabled){
				let next = await this.nextTabEnabled(index);
				if(isNull(next)){
					await this.redirectTo();
				}else{
					this.currentTab = next;
				}
			}else{
				this.currentTab = index;
			}
		},

		async redirectTo(campaignsId?: any, index?: any) {
			let route: any = { name: "CampaignsIndex" };

			if (campaignsId) {
				route = {
					name: "CampaignEdit",
					params: { id: campaignsId },
					query: { tabIndex: "1" },
				};
			}
			//this.setNotification({ title: "", message: "", type: "" });
			return this.$router.push(route);
		},

		currentTabSelected(item: { key: number }, index: number): Boolean {
			return item.key === index;
		},
		updateModelOverview(model: any) {
			this.campaign = model;
		},

		async nextTabEnabled(value: number){
			let tabNumber = null;
			this.getItems.some((t) => {
				if(t.key > value && !t.disabled){
					tabNumber = t.key;
					return true;
				}
			});
			return isNull(tabNumber) ? null : tabNumber;
		},

		/**
		 * GET
		 */
		async dispatchContinents() {
			const response = await this.continentsList();
			Object.entries(response).forEach(([key, valor]) => {
				let obj = {
					id: valor,
					value: key,
				};
				this.resources.continents.push(obj);
			});
		},

		async dispatchAdvertisers() {
			return this.$store.dispatch("advertiser/list", {
				filters: {} as AdvertiserFilters,
				options: { sort: "name", order: "asc" } as AdvertiserOptions,
			});
		},

		async dispatchAccount() {
			return this.$store.dispatch("account/getAccount", {
				root: true,
			});
		},

		async dispatchBudgetTypes() {
			return this.$store.dispatch("custom_list/getBudgetTypes", {
				root: true,
			});
		},

		async dispatchCampaignPacing() {
			return this.$store.dispatch("custom_list/getCampaignPacing", {
				root: true,
			});
		},

		async dispatchOptimizationStrategies() {
			return this.$store.dispatch(
				"custom_list/getOptimizationStrategies",
				{
					root: true,
				}
			);
		},

		async dispatchKpiCampaigns() {
			return this.$store.dispatch("custom_list/getKpiCampaigns", {
				root: true,
			});
		},

		async dispatchStrategies() {
			return this.$store.dispatch("custom_list/getStrategies", {
				root: true,
			});
		},

		async dispatchUnitTimes() {
			if (!isEmpty(this.getUnitTimes)) return;
			return this.$store.dispatch("custom_list/getUnitTimes", {
				root: true,
			});
		},

		async dispatchTraffickers() {
			return this.$store.dispatch(
				"user/traffickers",
				{ filters: { active: true }, options: {} },
				{ root: true }
			);
		},
		async dispatchTypesFrecuencyCaps() {
			if (!isEmpty(this.resources.types_frecuency_caps))
				return this.resources.types_frecuency_caps;
			this.$store
				.dispatch("custom_list/getTypesFrecuencyCaps")
				.then((res) => {
					this.resources.types_frecuency_caps = res;
				})
				.catch((err) => {
					this.resources.types_frecuency_caps = [];
				});
		},
		async show(id: number) {
			// if (!id) return;
			const campaign = await this.$store.dispatch("campaign/show", id, {
				root: true,
			});

			this.setCampaign(campaign);

			return campaign;
		},
		setIndexTab() {
			const tabIndex: any =
				!isUndefined(this.$route.query?.tabIndex) &&
				Number(this.$route.query?.tabIndex) > -1
					? Number(this.$route.query?.tabIndex)
					: undefined;

			if (tabIndex) {
				this.updateSelectedTabIndex(tabIndex);
			} else if (this.hasTabIndex) {
				this.updateSelectedTabIndex(this.getTabIndex);
			} else {
				this.updateSelectedTabIndex();
			}
		},
		async handleShow(id: Number) {
			try {
				await this.setLoadingData(TypeLoading.loading);
				await this.show(id);
				await this.setLoadingData();
			} catch (error) {
				console.error("handleShow", { error: error });
			} finally {
				await this.setLoadingData();
			}
		},

		configureSuccessMessage(shouldContinue: Boolean = false) {
			this.success.show = true;
			this.success.to = shouldContinue ? "" : "CampaignsIndex";
			this.success.show_redirect = true;
			this.success.query = { campaign_id: this.getID };
		},

		async processSubmission(params: {
			method: string;
			continue: Boolean;
			data: any;
		}) {
			const { method, continue: shouldContinue, data } = params;
			const updateParams = {
				id: this.getID,
				continue: shouldContinue,
				data,
			};

			await this.setLoadingData(TypeLoading.loading);
			await this.update(updateParams);
			await this.setLoadingData();

			this.configureSuccessMessage(shouldContinue);
			await this.setNotification(this.success);

			if (shouldContinue) {
				this.updateSelectedTabIndex(1);
				// this.$router.push({ name: 'LineItemCreate', query: { campaign_id: this.getID } });
			} else {
				// this.$router.push({ name: 'CampaignsIndex' });
			}
		},

		async handleSubmit(params: {
			method: string;
			continue: Boolean;
			data: any;
		}) {
			try {
				await this.processSubmission(params);
			} catch (error) {
			} finally {
				await this.setLoadingData();
			}
		},

		async setCampaign(campaign) {
			this.campaign = campaign;
			this.allows_events = campaign.allows_events;
			await this.cloneCampaign(campaign);
		},

		async update(params: {
			method: string;
			continue: Boolean;
			data: Campaign;
		}) {
			const campaign = await this.$store.dispatch(
				"campaign/update",
				params
			);

			this.setCampaign(campaign);
			return;
		},

		updateSelectedTabModifiersIndex(index: number) {
			this.currentTabModifiers = index;
		},

		async handleSubmitModifier(params: {
			entity: any;
			isBidModifier: any;
			isDeliveryModifier: any;
			executeDispatchFunction: () => any;
			executeClearFunction: () => any;
		}) {
			try {
				await this.setLoadingData(TypeLoading.loading);
				const result = await this.dispatchCreateModifier(
					params.entity,
					params?.isBidModifier,
					params?.isDeliveryModifier
				);

				if(params?.isBidModifier){
					this.getCampaign.modifier_id = result.id;
				}
				if(params?.isDeliveryModifier){
					this.getCampaign.delivery_modifier_id = result.id;
				}
				await this.setLoadingData();
				this.getCampaign.modifier_id = result.id;
				this.updateCountBidModifier = false;
				if (isUndefined(result) || isNull(result) || isEmpty(result)) {
					this.setNotification({
						title: this.$t("title-failed"),
						message: this.$t("failed"),
						btn_text: this.$t("try-again"),
						type: MessageTypes.ERROR,
						show: true,
					});
				} else {
					this.setNotification({
						title: this.$t("title-success"),
						message: this.$t("success"),
						btn_text: this.$t("continue"),
						type: MessageTypes.SUCCESS,
						show: true,
					});
					await params.executeDispatchFunction();
					await params.executeClearFunction();
				}
			} catch (error) {
				//console.error("handleSubmitModifier", { error: error });
			} finally {
				await this.setLoadingData();
			}
		},

		async handleCreateAssociation(params: {
			module: any;
			entity: { campaign_id: any };
			continue: Boolean;
		}) {
			try {
				await this.setLoadingData(TypeLoading.loading);
				const result = await this.dispatchCreateAssociation(
					params.module,
					params.entity
				);
				await this.setLoadingData();
				if (
					!isUndefined(result) &&
					!isNull(result) &&
					!isEmpty(result)
				) {
					if (params.continue) {
						/*this.success.to = {
							name: "LineItemCreate",
							query: { campaign_id: params.entity.campaign_id },
						};*/
						this.updateSelectedTabIndex(2);
						this.setNotification(this.success);
					} else {
						this.success.show = true;
						this.success.show_redirect = true;
						this.success.to = {
							name: "CampaignsIndex",
							// query: { campaign_id: this.getID },
						};
						this.setNotification(this.success);
					}
				}
			} catch (error) {
				console.error("handleCreateAssociation", { error: error });
			} finally {
				await this.setLoadingData();
			}
		},

		async dispatchCreateAssociation(module: string, entity: any) {
			let result: any = undefined;
			switch (module) {
				case "BID_MODIFIER":
					result =
						await this.dispatchCreateAssociationWithBidModifier(
							entity
						);
					break;
				case "DELIVERY_MODIFIERS":
					result =
						await this.dispatchCreateAssociationWithBidModifier(
							entity
						);
					break;
			}
			return result;
		},

		async dispatchCreateModifier(
			entity: any,
			isBidModifier: Boolean = false,
			isDeliveryModifier: Boolean = false
		) {
			return this.$store.dispatch(
				"modifier/createModifier",
				{
					modifier: entity,
					isBidModifier: isBidModifier,
					isDeliveryModifier: isDeliveryModifier,
				},
				{
					root: true,
				}
			);
		},

		async dispatchCreateAssociationWithBidModifier(entity: any) {
			return await this.$store.dispatch(
				"campaign/createAssociationWithModifier",
				{ entity: entity },
				{ root: true }
			);
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{
					root: true,
				}
			);
		},

		hasData(entity: any) {
			return !isUndefined(entity) && !isNull(entity);
		},

		handleLabel(event: { key: string; value: any }) {
			this.fieldLabel[event.key] = event.value;
		},

		async cloneCampaign(data: any) {
			let keysData = Object.keys(data);
			keysData.forEach((k) => {
				this.campaignTemp[k] = data[k];
			});
		},

		/**
		 * Return to list
		 */
		async handleCancel() {
			try {
				this.$router.push({ name: "CampaignsIndex" });
			} catch (error) {
				console.error("handleCancel", { error: error });
			}
		},
	},

	watch: {},
});
